<template>
    <div>
      <b-container class="pt-3 pb-3">
        <b-card>
            <div class="text-center">
                <img alt="Tixgo" src="../assets/logo.svg" class="mb-3" width="120" />
                <h1 class="fw-light">Oeps, u was ons te snel af!</h1>
                <p>
                    De pagina die u zoekt is mogelijk verwijderd, van naam veranderd of tijdelijk niet beschikbaar. Maar misschien kunnen wij u toch verder helpen!
                </p>
            </div>
        </b-card>
      </b-container>
    </div>
  </template>
  
  <script>
    export default {
        metaInfo: {
            title: 'Pagina niet gevonden',
        },
        components: {},
    }
  </script>
    
<style scoped>
</style>